import React from 'react';


const UsersIcon = () => {

    return (
        <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.58598 5.71582C8.43818 5.15763 9 4.20202 9 3.11688C9 1.39548 7.58617 0 5.84211 0C4.09805 0 2.68421 1.39548 2.68421 3.11688C2.68421 4.20202 3.24603 5.15763 4.09824 5.71582C1.72351 6.44791 0 8.63568 0 11.2208C0 11.6511 0.35346 12 0.789474 12C1.22549 12 1.57895 11.6511 1.57895 11.2208C1.57895 8.89688 3.48763 7.01299 5.84211 7.01299C8.19659 7.01299 10.1053 8.89688 10.1053 11.2208C10.1053 11.6511 10.4587 12 10.8947 12C11.3308 12 11.6842 11.6511 11.6842 11.2208C11.6842 10.1839 11.4069 9.21087 10.9215 8.3701C11.5003 7.91688 12.2617 7.63636 13.1053 7.63636C14.9972 7.63636 16.4211 9.01962 16.4211 10.5974C16.4211 11.0278 16.7745 11.3766 17.2105 11.3766C17.6465 11.3766 18 11.0278 18 10.5974C18 8.64873 16.6885 7.0423 14.8928 6.39056C15.5314 5.93804 15.9474 5.19878 15.9474 4.36364C15.9474 2.98651 14.8163 1.87013 13.4211 1.87013C12.0258 1.87013 10.8947 2.98651 10.8947 4.36364C10.8947 5.11662 11.2329 5.79165 11.7675 6.24885C11.0978 6.42409 10.4843 6.728 9.96183 7.13234C9.30464 6.48719 8.49279 5.99537 7.58598 5.71582ZM7.42106 3.11688C7.42106 3.97759 6.71414 4.67532 5.84211 4.67532C4.97008 4.67532 4.26316 3.97759 4.26316 3.11688C4.26316 2.25618 4.97008 1.55844 5.84211 1.55844C6.71414 1.55844 7.42106 2.25618 7.42106 3.11688ZM13.4211 5.2987C13.9443 5.2987 14.3684 4.88006 14.3684 4.36364C14.3684 3.84721 13.9443 3.42857 13.4211 3.42857C12.8978 3.42857 12.4737 3.84721 12.4737 4.36364C12.4737 4.88006 12.8978 5.2987 13.4211 5.2987Z" fill="#404268" />
        </svg>
    )

}

export default UsersIcon
