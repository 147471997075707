import React from 'react';


const ArtifactsIcon = () => {

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.15 0C9.56421 0 9.9 0.335787 9.9 0.75V1.65C9.9 2.06421 9.56421 2.4 9.15 2.4C8.73579 2.4 8.4 2.06421 8.4 1.65V0.75C8.4 0.335786 8.73579 0 9.15 0ZM9 12.9C11.1539 12.9 12.9 11.1539 12.9 9C12.9 6.84609 11.1539 5.1 9 5.1C6.84609 5.1 5.1 6.84609 5.1 9C5.1 11.1539 6.84609 12.9 9 12.9ZM9 14.4C11.9823 14.4 14.4 11.9823 14.4 9C14.4 6.01766 11.9823 3.6 9 3.6C6.01766 3.6 3.6 6.01766 3.6 9C3.6 11.9823 6.01766 14.4 9 14.4ZM9.9 16.35C9.9 15.9358 9.56421 15.6 9.15 15.6C8.73579 15.6 8.4 15.9358 8.4 16.35V17.25C8.4 17.6642 8.73579 18 9.15 18C9.56421 18 9.9 17.6642 9.9 17.25V16.35ZM15.2943 3.16637C15.5872 3.45926 15.5872 3.93414 15.2943 4.22703L14.6579 4.86343C14.365 5.15632 13.8901 5.15632 13.5972 4.86343C13.3043 4.57053 13.3043 4.09566 13.5972 3.80277L14.2336 3.16637C14.5265 2.87348 15.0014 2.87348 15.2943 3.16637ZM4.62735 14.791C4.92025 14.4981 4.92025 14.0232 4.62735 13.7303C4.33446 13.4374 3.85958 13.4374 3.56669 13.7303L2.9303 14.3667C2.6374 14.6596 2.6374 15.1345 2.9303 15.4274C3.22319 15.7203 3.69806 15.7203 3.99096 15.4274L4.62735 14.791ZM18 9.75C18 10.1642 17.6642 10.5 17.25 10.5H16.35C15.9358 10.5 15.6 10.1642 15.6 9.75C15.6 9.33579 15.9358 9 16.35 9H17.25C17.6642 9 18 9.33579 18 9.75ZM1.65 10.5C2.06421 10.5 2.4 10.1642 2.4 9.75C2.4 9.33579 2.06421 9 1.65 9H0.75C0.335786 9 0 9.33579 0 9.75C0 10.1642 0.335786 10.5 0.75 10.5H1.65ZM14.9667 15.4274C14.6738 15.7203 14.1989 15.7203 13.906 15.4274L13.2696 14.791C12.9767 14.4981 12.9767 14.0232 13.2696 13.7303C13.5625 13.4374 14.0374 13.4374 14.3303 13.7303L14.9667 14.3667C15.2596 14.6596 15.2596 15.1345 14.9667 15.4274ZM3.10603 4.8634C3.39892 5.1563 3.8738 5.1563 4.16669 4.8634C4.45958 4.57051 4.45958 4.09564 4.16669 3.80274L3.5303 3.16635C3.2374 2.87345 2.76253 2.87345 2.46964 3.16635C2.17674 3.45924 2.17674 3.93411 2.46964 4.22701L3.10603 4.8634Z" fill="#404268" />
        </svg>
    )

}

export default ArtifactsIcon
