import React from 'react';


const RuleIcon = () => {

    return (
        <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 0C0.447715 0 0 0.492487 0 1.1C0 1.70751 0.447715 2.2 1 2.2H15C15.5523 2.2 16 1.70751 16 1.1C16 0.492487 15.5523 0 15 0H1Z" fill="#404268" />
            <path d="M0 7.7C0 7.09249 0.447715 6.6 1 6.6H13C13.5523 6.6 14 7.09249 14 7.7C14 8.30751 13.5523 8.8 13 8.8H1C0.447715 8.8 0 8.30751 0 7.7Z" fill="#404268" />
            <path d="M1 13.2C0.447715 13.2 0 13.6925 0 14.3C0 14.9075 0.447715 15.4 1 15.4H17C17.5523 15.4 18 14.9075 18 14.3C18 13.6925 17.5523 13.2 17 13.2H1Z" fill="#404268" />
            <path d="M1 19.8C0.447715 19.8 0 20.2925 0 20.9C0 21.5075 0.447715 22 1 22H9C9.55229 22 10 21.5075 10 20.9C10 20.2925 9.55229 19.8 9 19.8H1Z" fill="#404268" />
        </svg>
    )

}

export default RuleIcon
