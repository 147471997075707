import React from 'react';


const CaseIcon = () => {

    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 1.77778H16.2L16.1999 3.55556H9C8.27451 3.55556 7.64563 3.23185 7.31964 3.05196C6.96105 2.85408 6.58824 2.60394 6.29062 2.40424L6.22467 2.36001C5.87972 2.12886 5.6213 1.95957 5.40277 1.8435C5.34278 1.81164 5.29848 1.79086 5.26843 1.77778H1.8V5.33333H16.2L16.1999 3.55556L16.2 1.77778C17.1941 1.77778 18 2.5737 18 3.55556V14.2222C18 15.2041 17.1941 16 16.2 16H1.8C0.805902 16 0 15.2041 0 14.2222V1.77778C0 0.795926 0.805902 0 1.8 0H5.4C5.9094 0 6.60571 0.466812 7.28472 0.922022C7.93078 1.35515 8.56118 1.77778 9 1.77778ZM1.8 7.11111V14.2222H16.2V7.11111H1.8Z" fill="#404268" />
        </svg>
    )

}

export default CaseIcon
