import { THEMES_KEYS } from "../constants/themes";

export default [
    {
        "id": THEMES_KEYS.HEADER.HEADER_COLOR,
        "title": "Header Color",
        "value" : "#000",
        "flag" : false
    },
    {
        "id": THEMES_KEYS.HEADER.TEXT_COLOR,
        "title": "Text Color",
        "value" : "#ccc",
        "flag" : false
    },
    
  ]